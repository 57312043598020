import request from '@/util/request'

//基础列表
export function list(data) {
    return request({
        url: '/hui-yuan-biao-qian/list',
        method: 'post',
        data
    })
}

//添加保存
export function add(data) {
    return request({
        url: '/hui-yuan-biao-qian/add',
        method: 'post',
        data
    })
}

//获取编辑页数据
export function getUpdate(id) {
    return request({
        url: `/hui-yuan-biao-qian/get-update/${id}`,
        method: 'get',
    })
}

//获取详情页数据
export function getDetail(id) {
    return request({
        url: `/hui-yuan-biao-qian/get-detail/${id}`,
        method: 'get',
    })
}

//编辑保存
export function update(data) {
    return request({
        url: '/hui-yuan-biao-qian/update',
        method: 'post',
        data
    })
}

//删除
export function del(id) {
    return request({
        url: `/hui-yuan-biao-qian/delete/${id}`,
        method: 'post',
    })
}

//批量删除
export function deleteBatch(data) {
    return request({
        url: '/hui-yuan-biao-qian/delete-batch',
        method: 'post',
        data
    })
}

//选择元列表
export function selector(data) {
    return request({
        url: '/hui-yuan-biao-qian/selector',
        method: 'post',
        data
    })
}

export function all() {
    return request({
        url: '/hui-yuan-biao-qian/all',
        method: 'get',
    })
}

/*
列表路由
{
    path: 'hui-yuan-biao-qian-list',
    component: () => import('@/view/hygl/hybq/HuiYuanBiaoQianList'),
    name: 'HuiYuanBiaoQianList',
    meta: {title: '会员标签', isTag: true, isMenu: true, searchable: true}
}

选择元
{key:'HuiYuanBiaoQianSelector', value:'会员标签'}

菜单识别:基础相关-list为入口
[
{"name":"会员标签基础列表","methodUrl":"/hui-yuan-biao-qian/list","operateType":"search","sort":1,"functionClassification":"0","_key":"1"},
{"name":"会员标签添加保存","methodUrl":"/hui-yuan-biao-qian/add","operateType":"add","sort":2,"functionClassification":"0","_key":"2"},
{"name":"会员标签获取编辑数据","methodUrl":"/hui-yuan-biao-qian/get-update/{id}","operateType":"search","sort":3,"functionClassification":"0","_key":"3"},
{"name":"会员标签获取详情数据","methodUrl":"/hui-yuan-biao-qian/get-detail/{id}","operateType":"search","sort":4,"functionClassification":"0","_key":"4"},
{"name":"会员标签编辑保存","methodUrl":"/hui-yuan-biao-qian/update","operateType":"edit","sort":5,"functionClassification":"0","_key":"5"},
{"name":"会员标签删除","methodUrl":"/hui-yuan-biao-qian/delete/{id}","operateType":"delete","sort":6,"functionClassification":"0","_key":"6"},
{"name":"会员标签选择元列表","methodUrl":"/hui-yuan-biao-qian/selector","operateType":"search","sort":18,"functionClassification":"0","_key":"18"},
{"name":"会员标签批量删除","methodUrl":"/hui-yuan-biao-qian/delete-batch","operateType":"delete","sort":7,"functionClassification":"0","_key":"7"}
]
*/
