<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open"
               :close-on-click-modal="false" destroy-on-close center append-to-body width="1000px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <el-form-item label="卡号" prop="kaHao" class="w50" width="50px">
                <el-input v-if="form.id!=null" v-model="form.kaHao" size="small" maxlength="36" disabled
                          show-word-limit/>

                <el-input v-else v-model="form.kaHao" size="small" maxlength="36" disabled show-word-limit/>
                <el-button v-if="form.id==null" type="text" @click="getDateStr">生成卡号</el-button>
            </el-form-item>
            <el-form-item label="姓名" prop="xingMing" class="w50">
                <el-input v-model="form.xingMing" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="手机号" prop="shouJiHao" class="w50">
                <el-input v-model="form.shouJiHao" size="small" maxlength="36" placeholder="请输入11位的手机号"
                          show-word-limit/>
            </el-form-item>
            <el-form-item label="会员生日" prop="huiYuanShengRi" class="w50">
                <el-date-picker v-model="form.huiYuanShengRi" size="small" type="date"
                                value-format="yyyy-MM-dd"/>
            </el-form-item>
            <el-form-item label="密码" prop="miMa" class="w50">
                <el-input v-if="form.id!=null" v-model="form.miMa" disabled size="small" maxlength="36"
                          show-word-limit/>
                <el-input v-if="form.id==null" v-model="form.miMa" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="会员类型" prop="huiYuanLeiXing" class="w50">
                <x-selector-one v-model="form.huiYuanLeiXing" dictType="T_HUI_YUAN_LX"/>
            </el-form-item>
            <el-form-item label="会员标签" prop="huiYuanBQ" class="line">
                <!--                <el-input v-if="form.id!=null " v-model="form.huiYuanBiaoQian" size="small" maxlength="" disabled-->
                <!--                          show-word-limit/>-->
                <!--                <el-input v-else v-model="form.huiYuanBiaoQian" size="small" maxlength="" show-word-limit/>-->
                <x-hy-tag-selector v-model="form.huiYuanBQ" edit></x-hy-tag-selector>
            </el-form-item>
<!--            <x-divider title="会员等级选择"></x-divider>-->
<!--            <el-form-item label="会员等级" prop="huiYuanDJ" v-if="form.huiYuanLeiXing===hylx.CGHY.key">-->
<!--                <hui-yuan-d-j-selector v-model="form.huiYuanDJ"/>-->
<!--            </el-form-item>-->
            <x-divider title="其他信息"></x-divider>

            <el-form-item v-show="false" label="充值金额" prop="chongZhiJinE" class="w50">
                <el-input v-if="form.id!=null" v-model="form.chongZhiJinE" size="small" maxlength="8" disabled
                          show-word-limit/>
                <el-input v-else v-model="form.chongZhiJinE" size="small" maxlength="8" show-word-limit>
                      <span slot="suffix">
                        {{ '元' }}
                      </span>
                </el-input>
            </el-form-item>
            <el-form-item label="赠送金额" prop="zengSongJinE" class="w50">
                <el-input v-if="form.id!=null" v-model="form.zengSongJinE" size="small" maxlength="8" disabled
                          show-word-limit/>
                <el-input v-else v-model="form.zengSongJinE" size="small" maxlength="8" show-word-limit>
                      <span slot="suffix">
                        {{ '元' }}
                      </span>
                </el-input>
            </el-form-item>
            <el-form-item v-show="false" label="累计积分" prop="leiJiJiFen" class="w50">
                <el-input v-if="form.id!=null" v-model="form.leiJiJiFen" size="small" maxlength="" disabled
                          show-word-limit/>
                <el-input v-else v-model="form.leiJiJiFen" size="small" maxlength="" disabled show-word-limit/>
            </el-form-item>
            <el-form-item label="赠送积分" prop="shengYuJiFen" class="w50">
                <el-input v-if="form.id!=null" v-model="form.shengYuJiFen" size="small" maxlength="" disabled
                          show-word-limit/>
                <el-input v-else v-model="form.shengYuJiFen" size="small" maxlength="" show-word-limit/>
            </el-form-item>
            <el-form-item label="所属门店" prop="suoShuMenDian" size="small">
                <el-select v-if="isShangHu" v-model="form.suoShuMenDian" placeholder="请选择">
                    <el-option v-for="item in menDianList"
                               :key="item.menDianMC"
                               :label="item.menDianMC"
                               :value="item.menDianMC">
                    </el-option>
                </el-select>
                <!--                <el-input v-if="isShangHu" v-model="form.suoShuMenDian" size="small" maxlength="36" disabled show-word-limit/>-->
                <el-input v-else v-model="form.suoShuMenDian" disabled size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item v-show="false" label="卡状态" prop="kaZhuangTai" size="small">
                <!--                <el-input v-model="form.kaZhuangTai" size="small" maxlength="" disabled show-word-limit/>-->
                <x-dict-show :code="form.kaZhuangTai" dictType="T_KA_ZT"/>
            </el-form-item>
            <el-form-item label="开卡时间" prop="kaiKaSJ">
                <el-date-picker v-if="form.id!=null" v-model="form.kaiKaSJ" size="small" type="datetime" disabled
                                value-format="yyyy-MM-dd HH:mm:ss"/>
                <el-date-picker v-else v-model="form.kaiKaSJ" size="small" type="datetime" disabled
                                value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
            <el-form-item v-show="false" label="是否注销" prop="shiFuZhuXiao" size="small">
                <!--                <el-input  v-model="form.shiFuZhuXiao" size="small" maxlength="" disabled show-word-limit/>-->
                <x-dict-show :code="form.shiFuZhuXiao" dictType="T_YES_OR_NO"/>
            </el-form-item>
            <el-form-item v-show="false" label="是否删除" prop="shiFuShanChu" size="small">
                <!--                <el-input v-model="form.shiFuShanChu" size="small" maxlength="" disabled show-word-limit/>-->
                <x-dict-show :code="form.shiFuShanChu" dictType="T_YES_OR_NO"/>
            </el-form-item>

            <el-form-item label="开卡人" prop="caoZuoRen">
                <el-input v-model="form.caoZuoRen" size="small" disabled maxlength="36" show-word-limit/>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add, update, getUpdate} from "@/service/hygl/HuiYuanXX";
    import * as menDianXXservice from "@/service/mdgl/MenDianXX";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';
    import * as hyxxservice from "@/service/hygl/HuiYuanXX";
    import {DICT_TYPE_HUI_YUAN_LX, DICT_TYPE_KA_ZT, DICT_TYPE_ZHUXIAO_ZT, DICT_TYPE_DEL_ZT, DICT_TYPE_BDCJ} from "@/util/constant";
    import HuiYuanDJSelector from "@/view/selector/HuiYuanDJSelector";
    import XHyTagSelector from "@/view/hygl/hybq/XHyTagSelector";

    export default {
        mixins: [XEditBase],
        components: {HuiYuanDJSelector, XHyTagSelector},
        beforeCreate() {
            let {required, mobilePhone, digits, positiveEightTwo} = ruleBuilder;

            this.rules = {
                kaHao: [required()], // 卡号
                xingMing: [required()], // 姓名
                shouJiHao: [required(), mobilePhone()], // 手机号
                miMa: [], // 密码
                huiYuanLeiXing: [required()], // 会员类型
                huiYuanBiaoQian: [], // 会员标签
                huiYuanBQ: [],
                plusZheKou: [], // plus折扣
                leiJiJiFen: [digits()], // 累计积分
                shengYuJiFen: [digits()], // 剩余积分
                shengYuJinE: [positiveEightTwo()], // 剩余金额
                chongZhiJinE: [positiveEightTwo()], // 充值金额
                zengSongJinE: [positiveEightTwo()], // 赠送金额
                // huiYuanDJ: [required()], // 会员等级
                kaZhuangTai: [], // 卡状态
                kaiKaSJ: [required()], // 开卡时间
                shiFuZhuXiao: [], // 是否注销
                shiFuShanChu: [], // 是否删除
                suoShuMenDian: [required()], // 所属门店
                caoZuoRen: [], // 操作人
                huiYuanShengRi:[required()],
            }
            this.titlePrefix = '会员信息';
            this.defaultForm = {
                id: null,
                kaHao: "", // 卡号
                xingMing: "", // 姓名
                shouJiHao: "", // 手机号
                miMa: "000000", // 密码
                huiYuanLeiXing: "", // 会员类型
                huiYuanBiaoQian: "", // 会员标签
                huiYuanBQ: [], //
                plusZheKou: "", // plus折扣
                leiJiJiFen: 0, // 累计积分
                shengYuJiFen: 0, // 剩余积分
                shengYuJinE: 0, // 剩余金额
                chongZhiJinE: 0, // 充值金额
                zengSongJinE: 0, // 赠送金额
                huiYuanDJ: "", // 会员等级
                kaZhuangTai: DICT_TYPE_KA_ZT.ZC.key, // 卡状态
                kaiKaSJ: "", // 开卡时间
                shiFuZhuXiao: DICT_TYPE_ZHUXIAO_ZT.WZX.key, // 是否注销
                shiFuShanChu: DICT_TYPE_DEL_ZT.WSC.key, // 是否删除
                suoShuMenDian: "", // 所属门店
                caoZuoRen: "", // 操作人
                cj:DICT_TYPE_BDCJ.MDHT.key,
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        },
        data() {
            this.hyxxservice = hyxxservice;
            return {
                dqKaHao: "",
                isShangHu: true,
                hydjList: [
                    {
                        classification: "",
                        createTime: "",
                        creatorId: "",
                        huiYuanDJ: "",
                        huiYuanMC: "",
                        id: "",
                        ownerAreaCode: "",
                        ownerDeptCode: "",
                        ownerDeptName: "",
                        ownerOrganCode: "",
                        ownerOrganName: "",
                        updateTime: "",
                        updaterId: "",
                        xiangShouZheKou: null,
                        zuiDiJiFen: null,
                    }
                ],
                hylx: DICT_TYPE_HUI_YUAN_LX,
                menDianList: []
            }
        },
        methods: {
            getDateStr() {
                // 获取当前时间
                const now = new Date();
                const mendian = "01";
                // 获取年、月、日对应的数字字符串
                const yearStr = String(now.getFullYear()).slice(-2);
                const monthStr = String(now.getMonth() + 1).padStart(2, '0'); // 加1是因为月份从0开始计数，padStart是为了补齐两位数
                const dayStr = String(now.getDate()).padStart(2, '0');
                const hourStr = String(now.getHours()).padStart(2, '0');
                const minuteStr = String(now.getMinutes()).padStart(2, '0');
                const secondStr = String(now.getSeconds()).padStart(2, '0');

                // 组合生成数字字符串
                const dateString = mendian + yearStr + monthStr + dayStr + hourStr + minuteStr + secondStr;

                console.log(dateString);
                this.form.kaHao = dateString;
                this.form.kaiKaSJ = now
            },

        },
        mounted() {
            hyxxservice.hydjlist().then(res => {
                if (res.code === 200) {
                    console.log(res.data);
                    this.hydjList = res.data;
                }
            });


            menDianXXservice.getCurrentMenDianXXFromRedis().then(res => {
                if (!res.data) {
                    menDianXXservice.checkboxList().then(res => {
                        console.log(res);
                        if (res.code === 200) {
                            this.menDianList = res.data
                        }
                    });
                    this.defaultForm.caoZuoRen = this.$store.getters.user.name
                    // this.defaultForm.suoShuMenDian="商户"
                } else {
                    this.isShangHu = false;
                    this.defaultForm.caoZuoRen = this.$store.getters.user.name;
                    this.defaultForm.suoShuMenDian = res.data.menDianMC
                }
            })
        }
    }
</script>

<style scoped>

</style>