import request from '@/util/request'

//基础列表
export function list(data) {
    return request({
        url: '/hui-yuan-xx/list',
        method: 'post',
        data
    })
}

//添加保存
export function add(data) {
    return request({
        url: '/hui-yuan-xx/add',
        method: 'post',
        data
    })
}

//获取编辑页数据
export function getUpdate(id) {
    return request({
        url: `/hui-yuan-xx/get-update/${id}`,
        method: 'get',
    })
}

//获取详情页数据
export function getDetail(id) {
    return request({
        url: `/hui-yuan-xx/get-detail/${id}`,
        method: 'get',
    })
}

//编辑保存
export function update(data) {
    return request({
        url: '/hui-yuan-xx/update',
        method: 'post',
        data
    })
}

//删除
export function del(id) {
    return request({
        url: `/hui-yuan-xx/delete/${id}`,
        method: 'post',
    })
}

//批量删除
export function deleteBatch(data) {
    return request({
        url: '/hui-yuan-xx/delete-batch',
        method: 'post',
        data
    })
}

//独立查询
export function query(data) {
    return request({
        url: '/hui-yuan-xx/query',
        method: 'post',
        data
    })
}

export function all() {
    return request({
        url: '/hui-yuan-xx/all',
        method: 'get',
    })
}


export function total(hylx) {
    return request({
        url: `/hui-yuan-xx/get-total-data/${hylx}`,
        method: 'post',
    })
}

export function chongzhi(data) {
    return request({
        url: '/hui-yuan-xx/chong-zhi',
        method: 'post',
        data
    })
}

export function dongjie(data) {
    return request({
        url: '/hui-yuan-xx/dong-jie',
        method: 'post',
        data
    })
}

export function jiedong(data) {
    return request({
        url: '/hui-yuan-xx/jie-dong',
        method: 'post',
        data
    })
}

export function xiaoka(data) {
    return request({
        url: '/hui-yuan-xx/xiao-ka',
        method: 'post',
        data
    })
}

//修改密码
export function editPwd(data) {
    return request({
        url: '/hui-yuan-xx/editPwd',
        method: 'post',
        data
    })
}

//会员等级list
export function hydjlist() {
    return request({
        url: '/hui-yuan-dj/all',
        method: 'post',
    })
}

//修改信息
export function updateXX(data) {
    return request({
        url: '/hui-yuan-xx/updateXX',
        method: 'post',
        data
    })
}

//根据id获得获得余额记录
export function getYuEJL(data) {
    return request({
        url: '/hui-yuan-xx/getYuEJiLu',
        method: 'post',
        data
    })
}

export function setPlusZK(zheKou) {
    return request({
        url: `/hui-yuan-xx/setPlusZK/${zheKou}`,
        method: 'post',
    })
}

export function excelIExport(data) {
    return request({
        url: '/hui-yuan-xx/excel-export/',
        method: 'post',
        data
    })
}

export function getMyYhq(id) {
    return request({
        url: `/hui-yuan-xx/my-yhq/${id}`,
        method: 'post',
    })
}

export function changeToPlus(id) {
    return request({
        url: `/hui-yuan-xx/change-to-plus/${id}`,
        method: 'post',
    })
}

export function yuEKouKuan(data) {
    return request({
        url: '/hui-yuan-xx/yuEKouKuan',
        method: 'post',
        data
    })
}

/*
列表路由
{
    path: 'hui-yuan-xx-list',
    component: () => import('@/view/hygl/hyxx/HuiYuanXXList'),
    name: 'HuiYuanXXList',
    meta: {title: '会员信息', isTag: true, isMenu: true, searchable: true}
}

独立查询路由
{
    path: 'hui-yuan-xx-query',
    component: () => import('@/view/hygl/hyxx/HuiYuanXXQuery'),
    name: 'HuiYuanXXQuery',
    meta: {title: '会员信息查询', isTag: true, isMenu: true, searchable: true}
}

提醒列表
{
    path: 'hui-yuan-xx-remind-list',
    component: () => import('@/view/hygl/hyxx/HuiYuanXXRemindList'),
    name: 'HuiYuanXXRemindList',
    meta: {title: '会员信息提醒', isTag: true, isMenu: true, searchable: true}
}

菜单识别:基础相关-list为入口
[
{"name":"会员信息基础列表","methodUrl":"/hui-yuan-xx/list","operateType":"search","sort":1,"functionClassification":"0","_key":"1"},
{"name":"会员信息添加保存","methodUrl":"/hui-yuan-xx/add","operateType":"add","sort":2,"functionClassification":"0","_key":"2"},
{"name":"会员信息获取编辑数据","methodUrl":"/hui-yuan-xx/get-update/{id}","operateType":"search","sort":3,"functionClassification":"0","_key":"3"},
{"name":"会员信息获取详情数据","methodUrl":"/hui-yuan-xx/get-detail/{id}","operateType":"search","sort":4,"functionClassification":"0","_key":"4"},
{"name":"会员信息编辑保存","methodUrl":"/hui-yuan-xx/update","operateType":"edit","sort":5,"functionClassification":"0","_key":"5"},
{"name":"会员信息删除","methodUrl":"/hui-yuan-xx/delete/{id}","operateType":"delete","sort":6,"functionClassification":"0","_key":"6"},
{"name":"会员信息批量删除","methodUrl":"/hui-yuan-xx/delete-batch","operateType":"delete","sort":7,"functionClassification":"0","_key":"7"}
]
菜单识别:独立查询-query为入口
[
{"name":"会员信息独立查询","methodUrl":"/hui-yuan-xx/query","operateType":"search","sort":11,"functionClassification":"0","_key":"11"}
]
*/
