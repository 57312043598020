<template>
    <el-tooltip effect="dark" :content="opts.biaoQianMiaoShu" placement="top-start">
        <el-tag size="small" :type="opts.biaoQianLX == 'default' ? '' : opts.biaoQianLX" :closable="closable" @close="close(opts)">{{opts.biaoQianMC}}</el-tag>
    </el-tooltip>
</template>
<script>
    export default {
        name: "XHyTag",
        props: {
            opts: {
                type: Object,
                required: true,
                validator: (value) => {
                    return ['biaoQianMiaoShu', 'biaoQianLX', 'biaoQianMC'].every((item) => value.hasOwnProperty(item))
                }
            },
            closable: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            close(tag) {
                this.$emit("close", tag);
            }
        }
    }
</script>

<style scoped>

</style>