<template>
    <div >
        <div >
            <el-dialog title="会员信息" :visible.sync="xiangQingVisible" width="80%" >
                <!--            会员信息-->
                <div class="">
                    <el-row :gutter="18" style="text-align: center">
                        <el-col :span="6"  style="height: 100px">
                            <el-card shadow="hover">
                                卡号：{{hyxx.kaHao}} <br>
                                姓名：{{hyxx.xingMing}} <br>
                                手机号：{{hyxx.shouJiHao}} <br>
                            </el-card>
                        </el-col>
                        <el-col :span="6" >
                            <el-card shadow="hover" style="height: 100px">
                                会员标签：<x-hy-tag-selector v-model="hyxx.huiYuanBQ" ></x-hy-tag-selector>
                                会员生日：{{hyxx.huiYuanShengRi}}<br>
                            </el-card>
                        </el-col>
                        <el-col :span="6">
                            <el-card shadow="hover" style="height: 100px">
                                金额：{{hyxx.shengYuJinE}}<br>
                                积分： {{hyxx.shengYuJiFen}}<br>
                                会员卡等级：{{hyxx.huiYuanDJ}}<br>
                            </el-card>
                        </el-col>
                    </el-row>
                </div>
                <!--            标签页-->
                <div>
                    <div><label>最近会员卡记录</label></div>
                    <el-tabs v-model="activeName"  @tab-click="handleClick">
                        <el-tab-pane label="余额记录" name="first">
                            <el-table :data="yejl" style="width: 100%">
                                <el-table-column label="序号" type="index" width="50"></el-table-column>
                                <el-table-column prop="bianDongSJ" label="收/支日期" ></el-table-column>
                                <el-table-column prop="bianDongLX" label="收/支类型">
                                    <x-dict-show slot-scope="{row}" :code="row.bianDongLX" dictType="T_BianDong_LX" />
                                </el-table-column>
                                <el-table-column prop="bianDongJinE" label="收/支金额" ></el-table-column>
                            </el-table>
                        </el-tab-pane>
                        <el-tab-pane label="积分记录" name="second">
                            <el-table :data="jfjl" style="width: 100%">
                                <el-table-column label="序号" type="index"  width="50"></el-table-column>
                                <el-table-column prop="bianDongSJ" label="收/支日期"></el-table-column>
                                <el-table-column prop="bianDongLX" label="收/支类型" >
                                </el-table-column>
                                <el-table-column prop="bianDongJiFen" label="收/支积分"></el-table-column>
                            </el-table>
                        </el-tab-pane>
                        <el-tab-pane label="订单记录" name="third">
                            <el-table :data="ddjl" style="width: 100%">
                                <el-table-column label="序号" type="index" width="50"></el-table-column>
                                <el-table-column prop="dingDanHao" label="订单号" ></el-table-column>
                                <el-table-column prop="xiaoFeiSJ" label="消费日期"></el-table-column>
                                <el-table-column prop="xiaoFeiJinE" label="消费金额"></el-table-column>
                            </el-table>
                        </el-tab-pane>
                    </el-tabs>
                </div>



                <!--            <div slot="footer" class="dialog-footer">-->
                <!--                <el-button @click="xiangQingVisible = false">取 消</el-button>-->
                <!--                <el-button type="primary" @click="xiangQingVisible = false">确 定</el-button>-->
                <!--            </div>-->
            </el-dialog>
        </div>

    </div>
</template>


<script>
    import * as hyxxservice from "@/service/hygl/HuiYuanXX";
    import XHyTagSelector from "@/view/hygl/hybq/XHyTagSelector";
    export default {
        name:"XiangQingxx",
        mixins: [],
        components: {XHyTagSelector},
        data() {
            return {
                activeName: 'first',
                hyxx: {
                    chongZhiJinE: null,
                    huiYuanDJ: '',
                    kaHao: "",
                    kaZhuangTai: "",
                    kaiKaSJ: "",
                    leiJiJiFen: null,
                    miMa: "",
                    shengYuJiFen: null,
                    shengYuJinE: null,
                    shiFuShanChu: "",
                    shiFuZhuXiao: "",
                    shouJiHao: "",
                    xingMing: "",
                    zengSongJinE: null,
                    huiYuanBQ:[],
                    huiYuanShengRi:"",
                },
                yejl:[{
                    id: null,
                    kaHao: "", // 卡号
                    shouJiHao: "", // 手机号
                    xingMing: "", // 姓名
                    bianDongJinE: "", // 变动金额
                    bianDongLX: "", // 变动类型
                    bianDongChangJing: "", // 变动场景
                    bianDongSJ: "", // 变动时间
                    caoZuoRen: "", // 操作人
                    bianDongMenDian: "", // 变动门店
                }],
                jfjl:[{
                    id: null,
                    kaHao: "", // 卡号
                    xingMing: "", // 姓名
                    bianDongJiFen: "", // 变动积分
                    bianDongLX: "", // 变动类型
                    bianDongSJ: "", // 变动时间
                    caoZuoRen: "", // 操作人
                    shouJiHao: "", // 手机号
                }],
                ddjl:[{
                    id: null,
                    dingDanHao: "", // 订单号
                    kaHao: "", // 卡号
                    xingMing: "", // 姓名
                    shouJiHao: "", // 手机号
                    xiaoFeiSJ: "", // 消费时间
                    xiaoFeiJinE: "", // 消费金额
                    dingDanZhuangTai: "", // 订单状态
                }],
                gridData: [{
                }],
                yuEJL:[{

                }],
                xiangQingVisible: false,
                form: {

                },
                formLabelWidth: '120px'
            };
        },
        methods:{
            handleClick(tab, event) {
                // console.log(tab, event);
            },
            showxx(id){
                this.yejl=null;
                this.jfjl=null;
                this.ddjl=null;
                this.xiangQingVisible=true;
                hyxxservice.getDetail(id).then(res=>{
                    // console.log(res);
                    if (res.code===200){
                        this.hyxx=res.data;
                        var data1={
                            id:id,
                            current:1,
                            size:10,
                        };
                        hyxxservice.getYuEJL(data1).then(res=>{
                            console.log(res.data)
                            if (res.data){
                                if (res.data.yuEJiLus){
                                    this.yejl=res.data.yuEJiLus;
                                }
                                if (res.data.jiFenJiLus){
                                    this.jfjl=res.data.jiFenJiLus;
                                }
                                if (res.data.dingDanJiLus){
                                    this.ddjl=res.data.dingDanJiLus;
                                }
                            }
                            // console.log(this.yejl);
                            // console.log(this.jfjl);
                            // console.log(this.ddjl)
                        })
                    }
                })
            }
        }
    };
</script>

<style lang="scss" scoped>
    .el-dialog-div{
        height: 60vh;//如果高度过高，可用max-height
        overflow: auto;
    }
</style>