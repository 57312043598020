<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
        destroy-on-close center append-to-body width="1000px">
        <x-detail label-width="140px" :entity="entity">
            <x-detail-item prop="kaHao" label="卡号" />
            <x-detail-item prop="xingMing" label="姓名" />
            <x-detail-item prop="shouJiHao" label="手机号" />
<!--            <x-detail-item prop="miMa" label="密码" />-->
            <x-detail-item prop="leiJiJiFen" label="累计积分" />
            <x-detail-item prop="shengYuJiFen" label="剩余积分" />
            <x-detail-item prop="shengYuJinE" label="剩余金额" />
            <x-detail-item prop="chongZhiJinE" label="充值金额" />
            <x-detail-item prop="zengSongJinE" label="赠送金额" />
            <x-detail-item prop="huiYuanDJ" label="会员等级" />
            <x-detail-item prop="kaZhuangTai" label="卡状态" />
            <x-detail-item prop="kaiKaSJ" label="开卡时间" />
            <x-detail-item prop="shiFuZhuXiao" label="是否注销" />
            <x-detail-item prop="shiFuShanChu" label="是否删除" />
            <x-detail-item prop="suoShuMenDian" label="所属门店" />
            <x-detail-item prop="caoZuoRen" label="操作人" />
        </x-detail>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/hygl/HuiYuanXX";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                kaHao: "", // 卡号
                xingMing: "", // 姓名
                shouJiHao: "", // 手机号
                miMa: "", // 密码
                leiJiJiFen: "", // 累计积分
                shengYuJiFen: "", // 剩余积分
                shengYuJinE: "", // 剩余金额
                chongZhiJinE: "", // 充值金额
                zengSongJinE: "", // 赠送金额
                huiYuanDJ: "", // 会员等级
                kaZhuangTai: "", // 卡状态
                kaiKaSJ: "", // 开卡时间
                shiFuZhuXiao: "", // 是否注销
                shiFuShanChu: "", // 是否删除
                suoShuMenDian: "", // 所属门店
                caoZuoRen: "", // 操作人
            };
            this.getDetailService = getDetail;
        }
    }
</script>

<style scoped>

</style>