<template>
    <div class="x-bq-selector">
        <x-hy-tag v-for="tag in selectedTags" :opts="tag" :key="tag.id" :closable="edit" @close="close"></x-hy-tag>
        <el-popover placement="bottom" width="500"
                    trigger="click">
            <div>
                <div class="x-bq-search">
                    标签名称:
                    <el-input v-model="search" size="mini" style="display: inline-block;width:150px;margin-right: 8px"
                              @input="tagSerachInput"></el-input>
                    <el-button size="mini" type="primary" @click="clear">清 空</el-button>
                    <el-button size="mini" type="primary" @click="selectAll">全 选</el-button>
                    <el-button size="mini" type="primary" @click="removeAll">全不选</el-button>
                </div>
                <div class="x-bq-body">
                    <el-tooltip v-for="tag in tags" :key="tag.id" effect="dark" :content="tag.biaoQianMiaoShu" placement="top-start">
                        <el-tag v-show="tag.show" size="small" :type="tag.biaoQianLX == 'default' ? '' : tag.biaoQianLX" @click="selectTag(tag)">
                            {{tag.biaoQianMC}}
                        </el-tag>
                    </el-tooltip>
                </div>
            </div>
            <el-tag slot="reference" class="x-bq-btn" v-if="edit" type="primary" size="small">+</el-tag>
        </el-popover>
    </div>
</template>
<script>
    import XHyTag from "@/view/hygl/hybq/XHyTag";
    import {all} from "@/service/hygl/HuiYuanBiaoQian";

    export default {
        name: "XHyTagSelector",
        components: {XHyTag},
        data() {
            return {
                tags: [],
                search: ""
            }
        },
        async beforeMount() {
            await all().then((response) => {
                this.tags = response.data;
                this.tags.forEach((obj) => {
                    obj.show = true;
                })

                // this.model=this.tags
                console.log(this.tags)
                console.log(this.model)
            })
        },
        computed: {
            model: {
                get() {
                    return this.value;
                },
                set(newVal, oldVal) {
                    this.$emit("input", newVal);
                }
            },
            selectedTags() {
                return this.tags.filter((obj) => this.model.indexOf(obj.id) > -1);
            }
        },
        props: {
            value: {
                type: Array,
                required: true
            },
            edit: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            close({id}) {
                let index = this.model.indexOf(id);
                if (index !== -1) {
                    this.model.splice(index, 1);
                }
            },
            selectTag({id}) {
                if (this.model.indexOf(id) === -1) {
                    this.model.push(id);
                }
            },
            tagSerachInput() {
                if (this.search) {
                    this.tags.forEach((obj) => {
                        if (obj.biaoQianMC.indexOf(this.search) > -1) {
                            obj.show = true;
                        } else {
                            obj.show = false;
                        }
                    })
                } else {
                    this.tags.forEach((obj) => {
                        obj.show = true;
                    })
                }
            },
            clear() {
                this.search = "";
                this.tagSerachInput();
            },
            selectAll() {
                this.model = this.tags.map(tag => tag.id);
            },
            removeAll() {
                this.model = [];
            }
        }
    }
</script>

<style scoped>
</style>